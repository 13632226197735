/* successPage.css */

/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Full-Page Styling */
  html, body {
    height: 100%;
    font-family: 'Quicksand', sans-serif;
    background-color: #f4f4f4;
    color: #333;
  }
  
  /* Success Page Container */
  .success-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    text-align: center;
    background-color: #fff;
    padding: 20px;
    gap: 15px;
  }
  
  /* Typography */
  h2 {
    font-size: 2.5rem;
    color: #161925;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 2rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Dashboard Button */
  .dashboard-button {
    padding: 15px 30px;
    background-color: #79A0e9;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dashboard-button:hover {
    background-color: #135194;
  }
  
  /* Centered Loading/Error */
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 1.2rem;
    z-index: 1000;
    transition: opacity 0.3s ease;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #fff;
    border-top: 6px solid #4CAF50; /* Color of the spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }