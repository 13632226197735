/* Overlay to cover the screen */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1005;
  }
  
  /* Content container */
  .popup-content {
    background-color: white;
    padding: 30px;
    position: relative;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 600px;
    width: 100%;
  }
  
  /* Close button in the top-right */
  .popup-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 22px;
    cursor: pointer;
    color: #888;
    transition: color 0.3s;
  }
  
  .popup-close-button:hover {
    color: #333;
  }
  
  /* Upgrade button */
  .popup-upgrade-button {
    background-color: #79A0e9;
    color: white;
    border: none;
    padding: 15px 60px;
    font-size: 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.3s;
  }
  
  .popup-upgrade-button:hover {
    background-color: #5d8de0;
  }