/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styling */
body {
  font-family: 'Quicksand', sans-serif;
  color: #333;
  background-color: #f4f4f4;
}

/* Container Styling */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styling */
h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 40px;
}

/* Pricing Section */
.pricing-section {
  padding: 60px 20px;
  background-color: #fff;
}

.pricing-tiers {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.tier-card {
  font-family: 'Quicksand', sans-serif;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  text-align: left;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative; /* Make this element the reference for absolute positioning */
  min-height: 400px; /* Ensure the card has enough height to push content upwards */
}

.tier-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.tier-card h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.tier-card p.tier-price {
  font-size: 1.25rem;
  color: #161925;
  margin-bottom: 20px;
  text-align: center;
}

.tier-features {
  list-style-type: none;
  margin-bottom: 60px;
}

.tier-features li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1rem;
}

.tier-features img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Button Styles */
.tier-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  padding: 10px;
  font-family: 'Quicksand', sans-serif;
  background-color: #79A0e9;
  color: #efefef;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 1000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tier-button2 {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #161925; /* Dark background */
  font-family: 'Quicksand', sans-serif;
  font-size: 12pt; /* Match font size */
  color: #EFEFEF; /* Light text */
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  display: flex; /* Enable flex for the arrow */
  align-items: center; /* Center vertically */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.tier-button:hover {
  background-color: #135194;
}
.tier-button2:hover {
  background-color: #DCCCBB; /* Hover background color */
  color: #161925; /* Change text color on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Arrow Styling */
.tier-button2::after {
  content: '→'; /* Right arrow */
  margin-left: 10px; /* Space between text and arrow */
  font-size: 1.25rem; /* Adjust arrow size */
}

.feature-title {
  display: flex;
  justify-content: center; /* Centers the entire h2 with the image */
  text-align: center; /* Centers the text and image within the h2 */
  margin-top: -50px;
}

.step-title {
  text-align: center;
  margin-top: -110px;
  margin-bottom: -110px;
  color: #161925;
  display: flex;
  align-items: center;
  font-size: 2rem; /* Adjust as needed */
  font-weight: bold;
}

.title-image {
  margin: 1px; /* Add some space around the image */
  height: 10em; /* Adjust to match the text size */
  vertical-align: middle; /* Aligns the image with the text */
  margin-left: -35px;
  margin-right: -35px;
}