.dropdown {
    position: relative;
    display: inline-block;
  z-index: 1000; /* Ensure dropdown has higher stacking order */
    margin-right: 10px; /* Reduces space between filters */
  }
  
  .dropdown-button {
    padding: 10px 15px;
    width: 200px; /* Increases the width of the dropdown button */
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-button::after {
    content: '▼'; /* Arrow indicator for dropdown */
    font-size: 12px;
    margin-left: 10px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 100%; /* Make the dropdown menu match the button's width */
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .dropdown-item input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .dropdown-item label {
    font-size: 14px;
  }
  
  .dropdown-menu input[type="checkbox"] {
    transform: scale(1.2);
    margin-right: 8px;
  }
  
  .dropdown-item:last-child {
    margin-bottom: 0;
  }
  
  .filters {
    z-index: 999;
    display: flex;
  justify-content: center;
  margin-bottom: 20px; 
    gap: 20px; /* Adjusts spacing between filters */
  }
  