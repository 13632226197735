.dashboard-container {
  padding: 20px;
  background-color: #f4f4f4;
  font-family: 'Quicksand', sans-serif;
  color: #333;
}

/* Welcome section styling */
.welcome {
  font-size: 24px;
  margin-top: 3px;
  margin-left: 5px;
  margin-bottom: 25px;
}

.dash-step-title {
  text-align: center;
  margin-top: -110px;
  margin-bottom: -110px;
  color: #161925;
  display: flex;
  align-items: center;
  font-size: 1.7rem; /* Adjust as needed */
}

.dash-title-image {
  margin: 1px; /* Add some space around the image */
  height: 10em; /* Adjust to match the text size */
  vertical-align: middle; /* Aligns the image with the text */
  margin-left: -30px;
  margin-right: -30px;
  z-index: 10; 
}

.user {
  padding-left: 10px;
}

.dashboard-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

/* Donut chart and line chart */
.total-donut-container {
  position: relative;
  width: 400px; /* Doubled size from 200px to 400px */
  height: 400px; /* Ensure height matches the width */
  margin-left: 3%; /* This shifts it a little more to the right */
  align-items: center;
}

.donut-container {
  position: relative;
  width: 140px;
  align-items: center;
}

.line-chart-container {
  flex-grow: 2; /* Make this chart grow in size relative to other elements in the row */
  height: 400px; /* Increase the height of the line chart */
  width: 600px; /* Increase the width of the line chart */
  margin-left: 5%; /* Space between donut and line chart */
}

.total-donut-label {
  position: absolute;
  top: 50%; /* Adjusted for new size */
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px; /* Increased font size for larger chart */
  text-align: center;
  line-height: 1.2; /* Adjust line height to fine-tune spacing between lines */
}

.donut-label {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

select {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

option {
  padding: 5px;
}

.archetypes-title2 {
  font-size: 35px;
  margin-bottom: 20px;
  color: black;
}

.archetypes-title {
  font-size: 35px;
  margin-bottom: 20px;
}

.archetypes-row {
  display: flex;
  justify-content: flex-start; /* Align items to the left side to control positioning */
  justify-content: space-between;
}

.emoji-label {
  display: flex;
  justify-content: center;  /* Centers items horizontally */
  align-items: center;      /* Centers items vertically */
  margin-top: 3px;
  font-size: 20px;
  text-align: center;
}

.archetype-insights-container {
  margin-bottom: 30px;
  margin-top: 20px;
}

.archetype-insights-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

.archetyped-box {
  text-align: center;
  margin-top: 20px;
}

.archetyped-count {
  font-size: 36px;
  margin: 0;
}