/* Import Quicksand Font */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

/*@import "tailwindcss/base";*/
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Body and General Settings */
body {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(-45deg, #EFEFEF, #DCCCBB, #161925);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  color: #161925; /* Dark text color */
}

/* App Wrapper */
.App {
  text-align: center;
}

/* Navbar */
.navbar {
  width: 100%;
  height: 70px;
  background: linear-gradient(-45deg, #79A0E9, #EFEFEF, #DCCCBB);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease-in;
}

.logo {
  width: 250px;
  height: auto;
  transition: transform 0.3s ease, width 0.3s ease;
}

.logo:hover {
  transform: scale(1.1);
}

.nav-buttons {
  display: flex;
  padding-right: 20px;
  gap: 15px;
}

/* CTA Button */
.cta-button {
  background-color: #161925; /* Blue accent */
  font-family: 'Quicksand', sans-serif;
  font-size: 12pt;
  color: #EFEFEF; /* Dark text */
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #DCCCBB;
  color: #161925;
  transform: translateY(-2px);
}

/* CTA Button */
.workButton {
  background-color: #efefef; /* Blue accent */
  font-family: 'Quicksand', sans-serif;
  font-size: 16pt;
  color: #161925; /* Dark text */
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.workButton:hover {
  background-color: #79a0e9;
  color: #161925;
  transform: translateY(-4px);
}

/* Hero section */

.learn {
  background: #2b343b;
  margin-bottom: 20px;
  padding-bottom: 80px;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #2b343b;
  color: white;
  height: 90vh;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Full viewport height */
  position: relative;
  }

.image-container {
  padding-left:20px;
  width: 60%;
  transition: transform 0.3s ease;
}

.image-container:hover {
  transform: scale(1.08);
}

.hero-image {
  width: 105%;
  height: auto;
}

.text-container {
  width: 40%;
  padding: 20px;
}

h2 {
  font-size: 40px;
  color: #efefef;
  text-decoration: underline;
  margin-bottom: 20px;
}

.archetype-list {
  list-style-type: none;
  padding: 0;
}

.archetype-list li {
  margin-bottom: 15px;
  font-size: 18px;
}

.arch {
  color: #efefef;
  font-size: 22px;
  text-decoration: underline;
  text-decoration-style: dashed;
}

.button-container {
  margin-top: -50px; /* Adjust the top margin as needed */
  display: flex;
  justify-content: center;
  position: relative;
  right: 79%;
}

/* Arrow Styling */
.arrow {
  position: absolute;
  top: 50%;
  right: calc(50% + 50px); /* Center the arrow and adjust space */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid yellow; /* Arrow color */
  transform: translateY(-50%); /* Center vertically */
}
/* Arch list styling */
.line {
  margin-right:0.25em;
}

.performanceColorGood {
	color: #629924;
	margin-right:0.25em;
	font-size: 20px;
	font-style: italic;
	transition-property: font-size;
	transition-duration: 1s
}

.performanceColorBad {
	color: #c33;
	margin-right:0.25em;
	font-size: 20px;
	font-style: italic;
	transition-property: font-size;
	transition-duration: 1s
}

.performanceColorGood:hover {
	transition-property: font-size;
	transition-duration: 1s ease;
	font-size: 35px;
  }

  .performanceColorBad:hover {
	transition-property: font-size;
	transition-duration: 1s ease;
	font-size: 35px;
  }

  .trending img{
width: 6%;
height: auto;
transition: transform 1s ease;
  }

  .trending img:hover {
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    transition: 1s ease;
    }

/* Steps */

/* Steps Section Styling */
.features {
  padding: 40px 20px;
  padding-top: 80px;
  background-color: #efefef;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 65%;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-title {
  display: flex;
  justify-content: center; /* Centers the entire h2 with the image */
  text-align: center; /* Centers the text and image within the h2 */
}

.step-title {
  text-align: center;
  margin-top: -110px;
  margin-bottom: -110px;
  color: #161925;
  display: flex;
  align-items: center;
  font-size: 2rem; /* Adjust as needed */
  font-weight: bold;
}

.title-image {
  margin: 1px; /* Add some space around the image */
  height: 10em; /* Adjust to match the text size */
  vertical-align: middle; /* Aligns the image with the text */
  margin-left: -35px;
  margin-right: -35px;
  z-index: 100; 
}

.steps-list {
  list-style: none;
  padding: 0;
}

.step-item {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 15px;
  color: #444;
}

.step-item a{
  padding-right: 5px;
}

.step-item img{
  width: 24px;
  height: auto;
  padding-right: 5px;
}

.step-link {
  color: #79A0e9;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.step-link:hover {
  color: #135194;
}

/* Footer */
.app-footer {
  background-color: #2b343b; /* Dark background */
  color: #EFEFEF; /* Light text */
  padding: 40px 20px;
  font-family: 'Quicksand', sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-branding {
  flex: 1;
  margin-right: 20px;
}

.footer-logo {
  width: 85px;
  height: auto;
  margin-top: 10px;
  margin-bottom: -10px;
}

.footer-description {
  font-size: 1.2rem;
  line-height: 1.6;
}

.footer-links, .footer-social {
  flex: 1;
  margin: 20px;
}

.footer-links h4, .footer-social h4 {
  font-size: 1.5rem;
  margin-bottom: 15px;

}

.footer-nav {
  list-style: none;
  padding: 0;
}

.footer-nav li {
  margin-bottom: 10px;
}

.footer-nav li a {
  color: #EFEFEF;
  font-size: 20px;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.footer-nav li a:hover {
  color: #79A0E9; /* Blue accent on hover */
}

.footer-social {
  text-align: center;
  margin: 20px 0;
}

.social-icons {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icons li {
  display: inline-block;
}

.social-icons li a {
  color: #EFEFEF;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons li a:hover {
  color: #79A0e9; /* Add a hover color of your choice */
}

.footer-bottom {
  margin-top: 40px;
  text-align: center;
  border-top: 1px solid #EFEFEF;
  padding-top: 20px;
  font-size: 0.9rem;
}

.footer-bottom p {
  margin: 0;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-branding, .footer-links, .footer-social {
    margin: 20px 0;
  }
}

/* Responsive Layout */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .nav-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .hero {
    padding: 100px 10px 60px;
  }

  .hero h2 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .hero .cta-button {
    padding: 12px 24px;
  }
}

/* Gradient Animation */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.progress-root {
  position: relative;
}

.progress-root::after {
  content: attr(title);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;
}

.progress-root:hover::after {
  opacity: 1;
}

:root {
  --color-primary: #79A0E9;
  --color-secondary: #e5e7eb;
  --color-success: #A8D5BA;
  --color-danger: #FF9A9A;
  --color-warning: #FFE8A3;
  --color-attempt: #79a0e9;
  --color-muted-foreground: #6b7280;
}

.bg-primary { background-color: var(--color-primary); }
.bg-secondary { background-color: var(--color-secondary); }
.bg-success { background-color: var(--color-success); }
.bg-danger { background-color: var(--color-danger); }
.bg-warning { background-color: var(--color-warning); }
.text-muted-foreground { color: var(--color-muted-foreground); }

.text-sm {
text-align: center;
font-size: 14px;
text-decoration: underline;
}